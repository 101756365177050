import React, { useCallback, useState, ChangeEvent, useEffect, useRef, useContext } from 'react';
import { Button, Label, Spinner, TextField, PageHeader, Content, ButtonWithLoading, ButtonWithIcon, AlertBar, usePoll, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LineArea from './LineArea';
import { DEFAULT_LINE_SET } from '../constants';
import { addArea, getAreaDetails, updateArea, removeArea, getStocks, getCameraDetails } from '../apis';
import SubHeading from '../components/SubHeading';
import { IPointSet } from 'scorer-ui-kit/dist/LineUI';
import Select from '../components/molecules/Select';
import { PercentageSlider, ISliderMark } from 'scorer-ui-kit';
import { ThemeProvider } from 'styled-components';
import { hollowPointsTheme } from '../customTheme';
import { useImage } from '../hooks/useImage';
import { LINEUI_IMAGE_INTERVAL } from '../constants';
import { StreamContext } from '../App';

const Container = styled.div`
    margin-top: 41px;
`;

const LineAreaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 39px;
  margin-bottom: 30px;
`;

const LineWrapper = styled.div`
    width: 68.5%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 36%;
    margin-left: 19px;
    margin-top: -10px;
`;

const TextFieldWrapper = styled.div`
    margin-bottom: 4px;
`;

const FieldLabel = styled(Label)`
    margin-bottom: 0px;
`;

const FormButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;

const CancelButton = styled(Button)`
    margin-right: 10px;
`;

const SpinnerWrapper = styled.div`
  margin-top: 10px;
`;

const RemoveAreaButton = styled(ButtonWithIcon)`
  margin-top: 40px;
`;

const AlertContainer = styled.div`
  margin-top: 20px;
`;

const RemoveSpinnerWrapper = styled.div`
  position: absolute;
  left: 6px;
  top: 43px;
`;

const RemoveButtonWrapper = styled.div`
  position: relative;
`;

const SliderWrapper = styled.div`
  margin-right: -6px;
  margin-left: -6px;
`;

const WarningSlider = styled(SliderWrapper)`
  margin-top: 25px;
  margin-bottom: 22px;
`;

const Marks: ISliderMark[] = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 20,
  },
  {
    value: 40,
  },
  {
    value: 60,
  },
  {
    value: 80,
  },
  {
    value: 100,
    label: '100%',
  },
];

const AreaForm = () => {
  const [area, setArea] = useState<any>({ color: 'primary', name: '', stock_id: 1, warning_level: 60, critical_level: 20, points: DEFAULT_LINE_SET });
  const [lineSet, setLineSet] = useState<IPointSet[]>(DEFAULT_LINE_SET);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [allStocks, setAllStocks] = useState([]);
  const [apiStocks, setApiStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState<string>('chooseStock');
  const [valAlert, setValAlert] = useState<Alert>({ key: new Date(), message: '', type: 'error' });
  const [stockLoading, setStockLoading] = useState(true);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const { state: { image, imgLoading, error }, actions: { fetchImage } } = useImage();
  const [areaNames, setAreaNames] = useState<string[]>([]);
  const [preAreaName, setPrevAreaName] = useState('');

  const { cameraID, areaID }: Params = useParams();
  const { push } = useHistory();
  const { stream, setStream } = useContext(StreamContext);
  const { t } = useTranslation(['EditAreas']);
  const { sendNotification } = useNotification();
  const notify = useRef<any>();
  notify.current = sendNotification;

  const getTitle = useCallback(() => {
    if (stream.desc) {
      return stream.desc + ' | Shelf Monitoring';
    }
    if (Object.keys(stream)[0]) {
      return Object.keys(stream)[0] + ' | Shelf Monitoring';
    }
    return 'Shelf Monitoring';
  }, [stream]);

  useEffect(() => {
    document.title = getTitle();
  }, [getTitle]);

  usePoll(async () => {
    fetchImage(Object.keys(stream)[0], true, stream[Object.keys(stream)[0]]);
  }, LINEUI_IMAGE_INTERVAL * 1000);

  const fetchCameraDetails = useCallback(() => {
    getCameraDetails(cameraID).then(({ data: { data, status_code, message } }) => {
      if (status_code === '0') {
        setStream({ [data.stream_name]: data.stream_type, desc: data.description });
        const areas = data.areas.map(({ name }: { name: string }) => name.toLowerCase());
        setAreaNames(areas);
      } else {
        notify.current({ type: 'error', message: message, isPinned: true });
      }
      setLoading(false);
    }).catch((err) => {
      console.error(err.message);
      setLoading(false);
      notify.current({ type: 'error', message: err.message, isPinned: true });
    });
  }, [cameraID, setStream]);

  useEffect(() => {
    fetchCameraDetails();
  }, [fetchCameraDetails]);

  const onFieldChange = useCallback((key) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setArea({ ...area, [key]: value });
  }, [area]);

  const updateSliderValue = useCallback((key, value) => {
    setArea({ ...area, [key]: value });
  }, [area]);

  const onCancel = () => {
    push(`/cameras/${cameraID}/edit-areas`);
  };

  const updateStateCallback = useCallback((newLinesData: IPointSet[]) => {
    setLineSet(newLinesData);
  }, []);

  const fetchAreaInfo = useCallback(() => {
    getAreaDetails(cameraID, areaID).then(({ data: { data, status_code, message } }) => {
      if (status_code === '0') {
        const newArea = { ...data.areas[0], points: [{ points: [...data.areas[0].points] }] };
        setLineSet(newArea.points);
        setArea(newArea);
        setPrevAreaName(newArea.name);
      } else {
        notify.current({ type: 'error', message: message, isPinned: true });
      }
      setLoading(false);
    }).catch((err) => {
      console.error(err.message);
      setLoading(false);
      notify.current({ type: 'error', message: err.message, isPinned: true });
    });
  }, [cameraID, areaID]);

  useEffect(() => {
    if (areaID) {
      fetchAreaInfo();
    } else {
      setLoading(false);
    }
  }, [fetchAreaInfo, areaID]);

  const onSave = useCallback(() => {
    setValAlert({ key: new Date(), message: '', type: 'error' });
    const { color, name, stock_id, warning_level, critical_level } = area;

    if (!isImage) {
      setValAlert({ key: new Date(), message: t('imageNotAvailable'), type: 'error' });
      return;
    }
    const jsonData = { color, name, stock_id, warning_level, critical_level, points: lineSet[0].points };

    if (!name) {
      setValAlert({ key: new Date(), message: t('pleaseEnterAreaName'), type: 'error' });
      return;
    }

    if (areaID) {
      if (name.toLowerCase() !== preAreaName.toLowerCase() && areaNames.includes(name.toLowerCase())) {
        setValAlert({ key: new Date(), message: t('areaNameAlreadyExists'), type: 'error' });
        return;
      }
    } else if (areaNames.includes(name.toLowerCase())) {
      setValAlert({ key: new Date(), message: t('areaNameAlreadyExists'), type: 'error' });
      return;
    }

    if (selectedStock === 'chooseStock') {
      setValAlert({ key: new Date(), message: t('pleaseSelectStockType'), type: 'error' });
      return;
    }

    const stockID: Stock[] = apiStocks.filter(({ stock_name }: Stock) => { return stock_name === selectedStock; });
    jsonData.stock_id = stockID[0].id;

    if (warning_level === 0 || critical_level === 0) {
      setValAlert({ key: new Date(), message: t('thresholdValidation'), type: 'error' });
      return;
    }

    if (warning_level <= critical_level) {
      setValAlert({ key: new Date(), message: t('warningAndCriticalLevelValidation'), type: 'error' });
      return;
    }

    setSaveLoading(true);
    if (areaID) {
      delete jsonData.color;
      updateArea(cameraID, areaID, jsonData).then(({ data: { status_code } }) => {
        if (status_code === '0') {
          push(`/cameras/${cameraID}/edit-areas`);
        }
        setSaveLoading(false);
      }).catch((err) => {
        console.error(err);
        setSaveLoading(false);
      });
    } else {
      addArea(cameraID, jsonData).then(({ data: { status_code } }) => {
        if (status_code === '0') {
          push(`/cameras/${cameraID}/edit-areas`);
        }
        setSaveLoading(false);
      }).catch((err) => {
        console.error(err);
        setSaveLoading(false);
      });
    }
  }, [area, cameraID, lineSet, areaID, push, t, selectedStock, apiStocks, isImage, areaNames, preAreaName]);

  const onRemove = useCallback(() => {
    setRemoveLoading(true);
    removeArea(cameraID, areaID).then(({ data: { status_code } }) => {
      if (status_code === '0') {
        push(`/cameras/${cameraID}/edit-areas`);
      }
      setRemoveLoading(false);
    }).catch((err) => {
      console.error(err);
      setRemoveLoading(false);
    });
  }, [cameraID, areaID, push]);

  const fetchStocks = useCallback(() => {
    getStocks().then(({ data: { data, status_code, message } }) => {
      if (status_code === '0') {
        if (data.length > 0) {
          setApiStocks(data);
          const stock = data.map(({ stock_name }: any) => stock_name);
          stock.unshift('chooseStock');
          setAllStocks(stock);
        } else {
          notify.current({ type: 'info', message: t('noStockMessage'), isPinned: true });
        }
      } else {
        notify.current({ type: 'error', message: message, isPinned: true });
      }
      setStockLoading(false);
    }).catch((err) => {
      console.error(err);
      setStockLoading(false);
      notify.current({ type: 'error', message: err.message, isPinned: true });
    });
  }, [t]);

  useEffect(() => {
    fetchStocks();
  }, [fetchStocks]);

  const onChangeStock = useCallback((filter: any) => {
    if (selectedStock === filter) {
      setSelectedStock('chooseStock');
    } else {
      setSelectedStock(filter);
    }
  }, [selectedStock]);

  useEffect(() => {
    if (areaID) {
      if (loading || stockLoading) return;
      const stockID: Stock[] = apiStocks.filter(({ id }: Stock) => { return area.stock_id === id; });
      setSelectedStock(stockID[0].stock_name);
    } else {
      setSelectedStock('chooseStock');
    }
  }, [loading, stockLoading, area.stock_id, apiStocks, areaID]);

  const otherColorHandler = (type: string) => {
    if (type === 'warning') {
      return 'warning';
    }
    return 'error';
  };

  const imageCallback = useCallback((value: boolean) => {
    setIsImage(value);
  }, []);


  return (
    <Content>
      <PageHeader title={stream.desc ? stream.desc : Object.keys(stream)[0]} icon='Camera' areaHref={`/cameras/${cameraID}/edit-areas`} areaTitle={t('cameraAndAreas')} introductionText={t('Cameras:introductionText')} />
      <Container>
        <SubHeading icon='FeatureLineUi' headingTitle={t('editArea')} />
        {
          loading ?
            <SpinnerWrapper>
              <Spinner styling='primary' size='large' />
            </SpinnerWrapper> :

            <LineAreaContainer>
              <LineWrapper>
                <ThemeProvider theme={hollowPointsTheme}>
                  <LineArea image={image} imgLoading={imgLoading} error={error} streamName={Object.keys(stream)[0]} linesData={area.points} updateStateCallback={updateStateCallback} imageCallback={imageCallback} />
                </ThemeProvider>
                {areaID &&
                  <RemoveButtonWrapper>
                    {removeLoading &&
                      <RemoveSpinnerWrapper>
                        <Spinner size='medium' styling='secondary' />
                      </RemoveSpinnerWrapper>}
                    <RemoveAreaButton position='left' icon='Delete' design='secondary' size='small' onClick={onRemove}>{t('removeArea')}</RemoveAreaButton>
                  </RemoveButtonWrapper>}
              </LineWrapper>
              <Form>
                <TextFieldWrapper>
                  <TextField
                    fieldState='default'
                    required
                    label={t('areaName')}
                    onChange={onFieldChange('name')}
                    value={area.name}
                    name='name'
                    placeholder={t('newArea')}
                    maxLength={255}
                  />
                </TextFieldWrapper>

                <FieldLabel htmlFor='stock-type' labelText={t('stockType')} />
                <Select onSelect={onChangeStock} selected={selectedStock} list={allStocks} />

                <WarningSlider>
                  <PercentageSlider
                    disabled={false}
                    inputCallback={(value) => updateSliderValue('warning_level', value)}
                    marks={Marks}
                    defaultValue={area.warning_level}
                    title={t('warningLevel')}
                    updateThumbColor={() => otherColorHandler('warning')}
                    updateTitle={() => t('warningLevel')}
                  />
                </WarningSlider>

                <SliderWrapper>
                  <PercentageSlider
                    disabled={false}
                    // step={7}
                    inputCallback={(value) => updateSliderValue('critical_level', value)}
                    marks={Marks}
                    defaultValue={area.critical_level}
                    title={t('criticalLevel')}
                    updateThumbColor={() => otherColorHandler('critical')}
                    updateTitle={() => t('criticalLevel')}
                  />
                </SliderWrapper>

                <FormButtons>
                  <CancelButton onClick={onCancel} size='small' design='secondary'>{t('Common:form.cancel')}</CancelButton>
                  <ButtonWithLoading loading={saveLoading} onClick={onSave} size='small'>{t('Common:form.save')}</ButtonWithLoading>
                </FormButtons>
                {valAlert.message !== '' &&
                  <AlertContainer key={valAlert.key.toString()}>
                    <AlertBar message={valAlert.message} type={valAlert.type} />
                  </AlertContainer>}
              </Form>
            </LineAreaContainer>
        }
      </Container>
    </Content>
  );
};

export default AreaForm;