import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import Fonts from './Fonts';
import Style from './style';
import { ThemeProvider } from 'styled-components';
import { hiddenPointsTheme } from './customTheme';
import { NotificationProvider } from 'scorer-ui-kit';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={hiddenPointsTheme}>
      <NotificationProvider>
        <App />
        <Fonts />
        <Style />
      </NotificationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
