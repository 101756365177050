import React, { useReducer, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LineUI, LineReducer, LineSetContext, Spinner } from 'scorer-ui-kit';
import NoImage from '../components/NoImage';

const Container = styled.div`
    min-height: 295px;
    position: relative;
`;

const OverlayContainer = styled.div<{ height: number }>`
  width: 100%;
  position: absolute;
  height: ${({ height }) => height}px;
  opacity: 0.65;
  background-color: #464646;
  z-index: 1;
`;

const LineArea: React.FC<any> = ({ linesData = [], updateStateCallback, updateImageHeight, overlayIndex = 0, imageCallback, image, imgLoading, error }) => {
  const [state, dispatch] = useReducer(LineReducer, linesData);
  const [imageHeight, setImageHeight] = useState<number>(295);

  const options = {
    showMoveHandle: false,
    // boundaryOffset: 10
  };

  useEffect(() => {
    if (updateStateCallback)
      updateStateCallback(state);
  }, [state, updateStateCallback]);

  useEffect(() => {
    if (!imgLoading) {
      if (error === '' && image !== '') {
        imageCallback && imageCallback(true);
      } else {
        imageCallback && imageCallback(false);
      }
      if (!updateImageHeight) return;
      setTimeout(() => {
        const height = document?.getElementById('line-ui')?.offsetHeight;
        updateImageHeight(height);
        setImageHeight(height || 295);
      }, 1);
    }

  }, [imgLoading, error, image, updateImageHeight, imageCallback]);

  useEffect(() => {
    if (overlayIndex) {
      const newLinesData: any = JSON.parse(JSON.stringify([...linesData])).filter(({ index }: PointSet) => overlayIndex === index);
      dispatch({
        type: 'LOAD',
        state: newLinesData
      });
    } else {
      dispatch({
        type: 'LOAD',
        state: [...linesData]
      });
    }
  }, [overlayIndex, linesData]);

  return (
    <Container id='line-ui' className={overlayIndex ? 'line-ui-overlay' : ''}>
      {overlayIndex !== 0 && <OverlayContainer height={imageHeight} />}
      <LineSetContext.Provider value={{ state, dispatch }}>
        {imgLoading ?
          <Spinner size='medium' styling='primary' /> :
          (error === '' && image !== '' ?
            <LineUI src={image} options={options} /> :
            <NoImage height='295px' />)}
      </LineSetContext.Provider>
    </Container>
  );
};

export default LineArea;