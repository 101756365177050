import React from 'react';
import styled from 'styled-components';
import { Icon } from 'scorer-ui-kit';

const Container = styled.div`{
  display: flex;
  margin-bottom: 34px;
  min-width: 165px;
}`;

const InnerContainer = styled.div`{
  display: flex;
  flex-direction: column;
}`;

const InfoTitle = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  font-weight: 500;
  color: rgba(120, 138, 144, 0.72);
  margin-bottom: 3px;
`;

const InfoValue = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: 500;
  color: #5a6269;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 12px;
  justify-content: center;
  text-align: center;
  margin-top: 9px;
`;

const CameraInfo: React.FC<CameraInfoProps> = ({ icon, infoTitle, value }) => {
  return (
    <Container>
      <IconWrapper>
        <Icon icon={icon} weight={icon === 'NotificationsOff' ? 'regular' : 'heavy'} color='dimmed' size={15} />
      </IconWrapper>
      <InnerContainer>
        <InfoTitle>{infoTitle}</InfoTitle>
        <InfoValue>{value}</InfoValue>
      </InnerContainer>
    </Container>
  );
};

export default CameraInfo;