import React, { useCallback, useEffect, useState, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Content, PageHeader, Switch, Label, Button, TextField, ButtonWithLoading, Spinner, AlertBar, useNotification } from 'scorer-ui-kit';
import Select from '../components/molecules/Select';
import { getEmailSettings, getEmailServices, updateEmailSettings, sendTestEmail, enableEmailNotification } from '../apis';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NotificationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  height: max-content;
  margin-right: 2.6%;
`;

const ToggleLabel = styled(Label)`
  margin-bottom: 0px;
  margin-left: 12px;
  margin-top: 4px;
  opacity: 0.5;
  font-size: 16px;
  color: #767676;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  max-width: 540px !important;
`;

const FieldLabel = styled(Label)`
  margin-bottom: 0px;
`;

const TextFieldWrapper = styled.div<{ marginTop?: string, marginBottom?: string }>`
  margin-top: ${({ marginTop }) => marginTop ? marginTop : '34px'};
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '-20px'};
`;

const FormButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
`;

const LeftButton = styled(Button)`
    margin-right: 10px;
`;

const AddButton = styled(Button)`
  height: 20px;
  padding: 3px 10.5px 3px 9.5px;
  font-size: 12px;
`;

const AddCCButton = styled(AddButton)`
  margin-right: 12px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  margin-bottom: -10px;
`;

const SendTestEmailButton = styled(ButtonWithLoading)`
  margin-right: 10px;
`;

const AlertContainer = styled.div`
  margin-top: 20px;
`;

const Settings = () => {
  const [emailSettings, setEmailSettings] = useState<any>();
  const [prevEmailSettings, setPrevEmailSettings] = useState<any>();
  const [selectedEmailConfig, setSelectedEmailConfig] = useState('chooseEmailService');
  const [prevSelectedEmailConfig, setPrevSelectedEmailConfig] = useState('chooseEmailService');
  const [allEmailServices, setAllEmailServices] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [show, setShow] = useState({ cc: false, bcc: false });
  const [testEmailLoading, setTestEmailLoading] = useState(false);
  const [valAlert, setValAlert] = useState<Alert>({ key: new Date(), message: '', type: 'error' });
  const [toggleState, setToggleState] = useState<any>('default');

  const { sendNotification } = useNotification();
  const { t } = useTranslation(['Settings']);

  const notify = useRef<any>();
  notify.current = sendNotification;

  const fetchSettings = useCallback(() => {
    getEmailSettings().then(({ data: { data, status_code, message } }) => {
      if (status_code === '0') {
        setEmailSettings(data);
        setPrevEmailSettings(data);
        if (data.email_service_name) {
          setSelectedEmailConfig(data.email_service_name);
          setPrevSelectedEmailConfig(data.email_service_name);
        }
        if (!data.to) {
          setIsEditable(true);
        }
      } else {
        notify.current({ type: 'error', message: message, isPinned: true });
      }
      setLoading(false);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
      notify.current({ type: 'error', message: err.message, isPinned: true });
    });
  }, []);

  const fetchEmailServices = useCallback(() => {
    getEmailServices().then(({ data: { data, status_code, message } }) => {
      if (status_code === '10000') {
        if (Object.keys(data.stacks).length) {
          const services = Object.keys(data.stacks);
          services.unshift('chooseEmailService');
          setAllEmailServices(services);
        } else {
          notify.current({ type: 'info', message: t('emailServicesSetupMessage'), actionTextButton: t('goToApiSettings'), onTextButtonClick: () => window.open(`http://${window.location.hostname}:20001/email-client`), isPinned: true });
        }
      } else {
        notify.current({ type: 'error', message: message, isPinned: true });
      }
    }).catch((err) => {
      console.error(err);
      notify.current({ type: 'error', message: err.message, isPinned: true });
    });
  }, [t]);

  useEffect(() => {
    fetchSettings();
    fetchEmailServices();
  }, [fetchSettings, fetchEmailServices]);

  const onClickToggle = useCallback(() => {
    if (emailSettings.enabled === 0 && !prevEmailSettings.sender) {
      notify.current({ type: 'error', message: t('pleaseAddEmailNotificationSettings'), isPinned: true });
      setEmailSettings({ ...emailSettings, enabled: 0 });
      setToggleState('failure');
      return;
    }
    setToggleState('loading');
    enableEmailNotification(emailSettings.enabled ? 'disable' : 'enable').then(({ data: { status_code } }) => {
      if (status_code === '0') {
        setToggleState('default');
        setEmailSettings({ ...emailSettings, enabled: emailSettings.enabled ? 0 : 1 });
        setPrevEmailSettings({ ...prevEmailSettings, enabled: emailSettings.enabled ? 0 : 1 });
      } else {
        setToggleState('failure');
      }
      setSaveLoading(false);
    }).catch((err) => {
      console.error(err);
      setSaveLoading(false);
      setToggleState('failure');
    });
  }, [emailSettings, prevEmailSettings, t]);

  const onChangeEmailConfig = useCallback((filter: any) => {
    if (selectedEmailConfig === filter) {
      setSelectedEmailConfig('chooseEmailService');
    } else {
      setSelectedEmailConfig(filter);
    }
  }, [selectedEmailConfig]);

  const onFieldChange = useCallback((key) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setEmailSettings({ ...emailSettings, [key]: value });
  }, [emailSettings]);

  const isEmailValid = useCallback((email) => {
    const regexp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regexp.test(email);
  }, []);

  const onSave = () => {
    setValAlert({ key: new Date(), message: '', type: 'error' });
    if (!emailSettings.sender) {
      setValAlert({ key: new Date(), message: t('senderIsRequired'), type: 'error' });
      return;
    }
    if (selectedEmailConfig === 'chooseEmailService') {
      setValAlert({ key: new Date(), message: t('pleaseSelectEmailConfig'), type: 'error' });
      return;
    }
    if (!emailSettings.to) {
      setValAlert({ key: new Date(), message: t('recipientIsRequired'), type: 'error' });
      return;
    }
    if (!emailSettings.subject) {
      setValAlert({ key: new Date(), message: t('subjectPrefixIsRequired'), type: 'error' });
      return;
    }

    if (!isEmailValid(emailSettings.sender)) {
      setValAlert({ key: new Date(), message: t('pleaseEnterValidSender'), type: 'error' });
      return;
    }

    if (!isEmailValid(emailSettings.to)) {
      setValAlert({ key: new Date(), message: t('pleaseEnterValidRecipient'), type: 'error' });
      return;
    }

    if (emailSettings.cc && !isEmailValid(emailSettings.cc)) {
      setValAlert({ key: new Date(), message: t('pleaseEnterValidCc'), type: 'error' });
      return;
    }

    if (emailSettings.bcc && !isEmailValid(emailSettings.bcc)) {
      setValAlert({ key: new Date(), message: t('pleaseEnterValidBcc'), type: 'error' });
      return;
    }

    emailSettings.email_service_name = selectedEmailConfig;
    setSaveLoading(true);
    updateEmailSettings(emailSettings).then(({ data: { status_code } }) => {
      if (status_code === '0') {
        setIsEditable(false);
        setPrevEmailSettings(emailSettings);
        setPrevSelectedEmailConfig(selectedEmailConfig);
      }
      setSaveLoading(false);
    }).catch((err) => {
      console.error(err);
      setSaveLoading(false);
    });
  };

  const onCancel = useCallback(() => {
    setIsEditable(false);
    setEmailSettings(prevEmailSettings);
    setShow({ cc: false, bcc: false });
    setSelectedEmailConfig(prevSelectedEmailConfig);
  }, [prevEmailSettings, prevSelectedEmailConfig]);

  const onEdit = useCallback(() => {
    setIsEditable(true);
  }, []);

  const onAdd = useCallback((key) => () => {
    if (!isEditable) return;
    setShow({ ...show, [key]: true });
  }, [show, isEditable]);

  const onSendTestEmail = useCallback(() => {
    setTestEmailLoading(true);
    const { sender, to, cc, bcc, subject } = emailSettings;
    const jsonData = { from: sender, to, cc, bcc, subject, body: 'This is test email from shelf monitoring' };
    sendTestEmail(selectedEmailConfig, jsonData).then(() => {
      setTestEmailLoading(false);
    }).catch((err) => {
      console.error(err);
      setTestEmailLoading(false);
    });
  }, [selectedEmailConfig, emailSettings]);

  return (
    <Content>
      <HeaderContainer>
        <PageHeader title={t('mailNotificationSettings')} introductionText={t('introductionText')} icon='ViewSettings' />
        <NotificationContainer>
          {emailSettings &&
            <>
              <Switch key={Math.random()} state={toggleState} checked={emailSettings.enabled ? true : false} onChangeCallback={onClickToggle} />
              <ToggleLabel htmlFor='' labelText={t('enable')} />
            </>}
        </NotificationContainer>
      </HeaderContainer>

      <Container>
        {
          loading ?
            <Spinner size='large' styling='primary' /> :
            emailSettings &&
              <>

                <TextFieldWrapper marginTop='0px'>
                  <TextField
                    fieldState={isEditable ? 'default': 'disabled'}
                    label={t('sender')}
                    onChange={onFieldChange('sender')}
                    value={emailSettings.sender}
                    name='sender'
                    placeholder='example@test.com'
                    maxLength={255}
                    disabled={!isEditable}
                  />
                </TextFieldWrapper>

                <TextFieldWrapper marginBottom='0px'>
                  <FieldLabel htmlFor='email-services' labelText={t('emailServices')} />
                  <Select icon='MetaCategories' title='' onSelect={onChangeEmailConfig} selected={selectedEmailConfig} list={allEmailServices} disabled={!isEditable} />
                </TextFieldWrapper>

                <TextFieldWrapper>
                  <TextField
                    fieldState={isEditable ? 'default': 'disabled'}
                    required
                    label={t('recipient')}
                    onChange={onFieldChange('to')}
                    value={emailSettings.to}
                    name='to'
                    placeholder='example@test.com'
                    maxLength={255}
                    disabled={!isEditable}
                  />
                  <ButtonsWrapper>
                    <AddCCButton onClick={onAdd('cc')} design='secondary'>{t('addCc')}</AddCCButton>
                    <AddButton onClick={onAdd('bcc')} design='secondary'>{t('addBcc')}</AddButton>
                  </ButtonsWrapper>
                </TextFieldWrapper>

                {(emailSettings.cc || show.cc) &&
                  <TextFieldWrapper>
                    <TextField
                      fieldState={isEditable ? 'default': 'disabled'}
                      label={t('cc')}
                      onChange={onFieldChange('cc')}
                      value={emailSettings.cc}
                      name='cc'
                      placeholder='example@test.com'
                      maxLength={255}
                      disabled={!isEditable}
                    />
                  </TextFieldWrapper>}

                {(emailSettings.bcc || show.bcc) &&
                  <TextFieldWrapper>
                    <TextField
                      fieldState={isEditable ? 'default': 'disabled'}
                      label={t('bcc')}
                      onChange={onFieldChange('bcc')}
                      value={emailSettings.bcc}
                      name='bcc'
                      placeholder='example@test.com'
                      maxLength={255}
                      disabled={!isEditable}
                    />
                  </TextFieldWrapper>}

                <TextFieldWrapper>
                  <TextField
                    fieldState={isEditable ? 'default': 'disabled'}
                    required
                    label={t('subjectPrefix')}
                    onChange={onFieldChange('subject')}
                    value={emailSettings.subject}
                    name='subject'
                    placeholder={t('subjectPrefix')}
                    maxLength={255}
                    disabled={!isEditable}
                  />
                </TextFieldWrapper>

                <FormButtons>
                  {
                    isEditable ?
                      <>
                        <LeftButton onClick={onCancel} design='secondary'>{t('Common:form.cancel')}</LeftButton>
                        <ButtonWithLoading loading={saveLoading} onClick={onSave}>{t('Common:form.save')}</ButtonWithLoading>
                      </> :
                      <>
                        {emailSettings.to && <SendTestEmailButton loading={testEmailLoading} onClick={onSendTestEmail} design='secondary'>{t('sendTestEmail')}</SendTestEmailButton>}
                        <Button onClick={onEdit}>{t('Common:form.edit')}</Button>
                      </>
                  }
                </FormButtons>
                {
                  valAlert.message !== '' &&
                    <AlertContainer key={valAlert.key.toString()}>
                      <AlertBar message={valAlert.message} type={valAlert.type} />
                    </AlertContainer>
                }
              </>
        }
      </Container>
    </Content>
  );
};

export default Settings;