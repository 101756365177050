import styled from 'styled-components';

const SelectList = styled.div<{expanded: boolean, boxWidth?: string; right?:string; }>`
  display: ${props => props.expanded ? 'block' :'none' };
  position: absolute;
  opacity: 0.99;
  border-radius: 2px;
  box-shadow: 0 5px 25px 0 #091018;
  box-shadow: 0 5px 15px 0 hsla(212, 45%, 6%, 0.11);
  background-color: hsla(0, 0%, 100%, 0.9);
  border-top: 5px solid hsl(205, 50%, 68%);
  margin-top: 10px;
  z-index: 2;
  width: ${props => props.boxWidth};
  right: ${props => props.right};
  max-height: 300px;
  overflow: auto;
`;

export default SelectList;
