import { lightTheme } from 'scorer-ui-kit';

export const hiddenPointsTheme = () => {
    lightTheme.custom.lines.primary = {
        'label': {
            'fill': '#fff'
        },
        'contrastLine': {
            'stroke': 'hsla(205deg, 80%, 45%, 100%);'
        },
        'highlightLine': {
            'stroke': 'hsla(205deg, 45%, 90%, 90%);'
        },
        'grabHandle': {
            'fill': 'transparent',
            'stroke': 'transparent'
        },
        'point': {
            'fill': 'transparent'
        },
        'grabHandleContrast': {
            'stroke': 'transparent'
        },
        'grabHandleText': {
            'fill': 'hsla(205deg, 80%, 25%, 100%);'
        },
        'handleBase': {
            'fill': 'transparent'
        },
        'handleRingLayer': {
            'stroke': 'transparent'
        },
        'handleReactiveFill': {
            'fill': 'transparent'
        },
        'handleReactiveRing': {
            'stroke': 'transparent'
        },
        'handleContrastLayer': {
            'stroke': 'transparent'
        },
        'stopStart': {
            'stopColor': 'transparent'
        },
        'stopEnd': {
            'stopColor': 'transparent'
        }
    };
    return lightTheme;
};

export const hollowPointsTheme = () => {
    lightTheme.custom.lines.primary = {
        'label': {
          'fill': '#fff'
        },
        'contrastLine': {
          'stroke': 'hsla(205deg, 80%, 45%, 100%);'
        },
        'highlightLine': {
          'stroke': 'hsla(205deg, 45%, 90%, 90%);'
        },
        'grabHandle': {
          'fill': 'hsla(205deg, 45%, 90%, 100%)',
          'stroke': 'hsla(205deg, 45%, 100%, 100%)'
        },
        'point': {
          'fill': 'hsla(205deg, 45%, 90%, 100%)'
        },
        'grabHandleContrast': {
          'stroke': 'hsla(205deg, 80%, 45%, 100%)'
        },
        'grabHandleText': {
          'fill': 'hsla(205deg, 80%, 25%, 100%);'
        },
        'handleBase': {
          'fill': 'hsla(235deg, 100%, 80%, 100%);'
        },
        'handleRingLayer': {
          'stroke': 'hsla(205deg, 100%, 89%, 100%);'
        },
        'handleReactiveFill': {
          'fill': 'hsla(192deg, 100%, 45%, 40%);'
        },
        'handleReactiveRing': {
          'stroke': 'hsla(205deg, 100%, 36%, 27%);'
        },
        'handleContrastLayer': {
          'stroke': 'hsla(205deg, 100%, 36%, 15%);'
        },
        'stopStart': {
          'stopColor': ' hsla(205deg, 100%, 15%, 35%);'
        },
        'stopEnd': {
          'stopColor': 'hsla(205deg, 100%, 15%, 0%)'
        }
      };
    return lightTheme;
};
