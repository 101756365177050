import React, { useState, useEffect, useCallback, createContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { MainMenu, Layout, TopBar } from 'scorer-ui-kit';
import styled from 'styled-components';

import logoMarkSvg from './svg/logo-mark.svg';
import logoTextSvg from './svg/logo-text.svg';
import {menu} from './pages/menu';

import Cameras from './pages/Cameras';
import Settings from './pages/Settings';
import CameraDetails from './pages/CameraDetails';
import EditAreas from './pages/EditAreas';
import AreaForm from './pages/AreaForm';

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StreamContext = createContext<StreamContextProps>({
  stream: {},
  setStream: (value: Stream) => { }
});

const App = () => {
  const [stream, setStream] = useState<Stream>({});

  const { t } = useTranslation(['Common']);
  const [translatedMenu, setTranslatedMenu] = useState(menu);

  const onLanguageChange = useCallback(() => {
    const language = i18n.language === 'ja' ? 'en' : 'ja';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, []);

  useEffect(() => {
    const translatedMenu = {
      items: menu.items.map(({ title, ...rest }) => ({ title: t(title), ...rest }))
    };
    setTranslatedMenu(translatedMenu);
  }, [t]);

  return (
    <Router>
      <Layout>
        <MainMenu content={translatedMenu} logoMark={logoMarkSvg} logoText={logoTextSvg} />
        <MainContainer>
          <TopBar
            loggedInUser=''
            onLanguageToggle={onLanguageChange}
            hasLogout={false}
            hasLanguage
          />
          <Switch>
            <StreamContext.Provider value={{ stream, setStream }}>
              <Route path='/' exact component={Cameras} />
              <Route path='/cameras' exact component={Cameras} />
              <Route path='/settings' exact component={Settings} />
              <Route path='/cameras/:cameraID' exact component={CameraDetails} />
              <Route path='/cameras/:cameraID/edit-areas' exact component={EditAreas} />
              <Route path='/cameras/:cameraID/add-area' exact component={AreaForm} />
              <Route path='/cameras/:cameraID/edit-areas/:areaID' exact component={AreaForm} />
            </StreamContext.Provider>
          </Switch>
        </MainContainer>
      </Layout>
    </Router>
  );
};

export default App;