import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Label, Icon, usePoll } from 'scorer-ui-kit';
import NoImage from '../components/NoImage';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTimeSince } from '../utils';
import { CAMERA_IMAGE_REFRESH_INTERVAL } from '../constants';
import { useImage } from '../hooks/useImage';
import { StreamContext } from '../App';

const Container = styled.div`
    width: 390px;
    margin: 0 20px 20px 0;
    padding: 0 0 10px;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(203, 218, 234, 0.41);
    background-color: #f9fafb;
`;

const CardImg = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
    cursor: pointer;
`;

const CardFooter = styled.div`
    padding: 13px 19px 1px 20px;
`;

const CardTitle = styled.div`
    margin-bottom: 3px;
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 18px;
    color: #5a6269;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 301px;
`;

const CardInfo = styled.div`
    display: flex;
    justify-content: space-between;    
`;

const InfoLabel = styled(Label)`
    margin: 7px 0 -4px 0;
    font-family: ${({ theme }) => theme.fontFamily.ui};
    font-size: 10px;
    color: rgba(120, 138, 144, 0.72);
`;

const InfoValue = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 12px;
    font-weight: 500;
    color: #6e7b86;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const InfoContainer = styled.div<{ extraWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({ extraWidth }) => extraWidth ? '40%' : '30%'};
`;

const StatusIcon = styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    margin-right: 9px;
    border-radius: 10px;
    margin-top: 3px;
    background-color: ${({ color }) => color};
`;

const StatusValue = styled.p<{ marginRight: string }>`
    margin-right: 9px;
    border-radius: 10px;
    margin: 0px;
    margin-right: ${({ marginRight }) => marginRight};
    background-color: ${({ color }) => color};
`;

const VerticleDevider = styled.div`
    width: 1px;
    height: 27px;
    opacity: 1;
    margin-right: 15px;
    margin-top: 5px;
    background-color: #eeeeee;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ImageContainer = styled.div`
    height: 220px;
`;

const StatusContainer = styled.div`
    display: flex;
`;

const Status: React.FC<StatusProp> = ({ iconColor, value, marginRight = '0px' }) => {

  return (
    <StatusContainer>
      <StatusIcon color={iconColor} />
      <StatusValue marginRight={marginRight}>{value}</StatusValue>
    </StatusContainer>
  );
};

const CameraCard: React.FC<CameraCardProps> = ({ camera: { stream_id, stream_name, description, last_analysis_time, last_checked, notification_enabled, status: { red, green, orange }, stream_type } }) => {
  const { state: { image, imgLoading }, actions: { fetchImage } } = useImage();
  const [cardImage, setCardImage] = useState(image);

  const { push } = useHistory();
  const { setStream } = useContext(StreamContext);
  const { t } = useTranslation(['Cameras']);

  const onImageError = () => {
    setCardImage('');
  };

  const gotoDetails = () => {
    setStream({ [stream_name]: stream_type, desc: description });
    push(`cameras/${stream_id}`);
  };

  usePoll(async () => {
    fetchImage(stream_name, false, stream_type);
  }, CAMERA_IMAGE_REFRESH_INTERVAL * 1000);

  useEffect(() => {
    setCardImage(image);
  }, [image]);

  return (
    <Container>
      <ImageContainer onClick={gotoDetails}>
        {imgLoading ? <NoImage isLoading={imgLoading} /> :
          cardImage !== '' ?
            <CardImg src={cardImage} alt={stream_name} onError={onImageError} /> :
            <NoImage />}
      </ImageContainer>
      <CardFooter>
        <TitleContainer>
          <CardTitle onClick={gotoDetails} title={stream_name + '\n' + description}>{description ? description : stream_name}</CardTitle>
          <Icon icon={notification_enabled ? 'Notifications' : 'NotificationsOff'} size={18} color='dimmed' />
        </TitleContainer>
        <CardInfo>
          <InfoContainer>
            <InfoLabel htmlFor='' labelText={t('lastUpdated')} />
            <InfoValue> {getTimeSince(last_analysis_time)} </InfoValue>
          </InfoContainer>
          <VerticleDevider />
          <InfoContainer>
            <InfoLabel htmlFor='' labelText={t('lastChecked')} />
            <InfoValue> {getTimeSince(last_checked)} </InfoValue>
          </InfoContainer>
          <VerticleDevider />
          <InfoContainer extraWidth>
            <InfoLabel htmlFor='' labelText={t('statusOverview')} />
            <InfoValue>
              <Status iconColor='#ff6666' value={red} marginRight='12px' />
              <Status iconColor='#f69b41' value={orange} marginRight='12px' />
              <Status iconColor='#5cc879' value={green} />
            </InfoValue>
          </InfoContainer>
        </CardInfo>
      </CardFooter>
    </Container>
  );
};

export default CameraCard;