import axios from 'axios';

const Axios = axios.create({
    baseURL: `${window.location.protocol}//${window.location.host}/api/v1`
    //baseURL: `http://192.168.30.10/api/v1`
});

const AxiosEdge = axios.create({
    baseURL: `${window.location.protocol}//${window.location.host}/edge/api/v1`
    //baseURL: `http://192.168.30.10/edge/api/v1`
});

export const getAllCameras = () => Axios.get('/streams/all');

export const getCameraImage = (streamName: string, original: boolean, streamType: string) => {
    let params = '';
    if (!original) {
        params = params + '&width=auto&height=220';
    }
    if (streamType === 'FTP') {
        params = params + '&ingest_time=true&save_original=true&fallback=true';
    }
    return AxiosEdge.get(`/stacks/${streamName}/snapshot?${params}&timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
}

export const getCameraDetails = (streamID: string) => Axios.get(`/streams/${streamID}/areas/all`);

export const updateCamera = (streamID: string, action: string) => Axios.post(`/streams/${streamID}`, { action });

export const getCheckedLogs = (streamID: string,) => Axios.get(`/streams/${streamID}/checklog`);

export const updateNotes = (streamID: string, notes: string) => Axios.patch(`/streams/${streamID}`, { notes });

export const getAreaDetails = (streamID: string, areaID: string) => Axios.get(`/streams/${streamID}/areas/${areaID}`);

export const addArea = (streamID: string, payload: AreaPayload) => Axios.put(`/streams/${streamID}/areas/all`, { ...payload });

export const updateArea = (streamID: string, areaID: string, payload: AreaPayload) => Axios.patch(`/streams/${streamID}/areas/${areaID}`, { ...payload });

export const removeArea = (streamID: string, areaID: string) => Axios.delete(`/streams/${streamID}/areas/${areaID}`);

export const getStocks = () => Axios.get('/stocks');

export const getEmailSettings = () => Axios.get('/email');

export const getEmailServices = () => AxiosEdge.get('/sdk/email_clients/all');

export const updateEmailSettings = (payload: any) => Axios.put('/email', payload);

export const sendTestEmail = (instanceName: string, payload: any) => AxiosEdge.post(`/sdk/email_clients/${instanceName}/all`, {
    namespace: 'sdk',
    type: 'email-client',
    action: 'send_test_email',
    ...payload
});

export const enableEmailNotification = (action: string) => Axios.post(`/email`, { action });