export const ANALYSIS_IMAGE_DIR = process.env.REACT_APP_ANALYSIS_IMAGE_DIR;
export const CAMERA_IMAGE_REFRESH_INTERVAL: any = process.env.REACT_APP_CAMERA_IMAGE_REFRESH_INTERVAL || 30;
export const LINEUI_IMAGE_INTERVAL: any = process.env.REACT_APP_LINEUI_IMAGE_INTERVAL || 5;
export const ANALYSIS_IMAGE_REFRESH_INTERVAL = 5; //sec
export const CAMERA_DETAILS_REFRESH_INTERVAL = 10; //sec
export const CAMERAS_REFRESH_INTERVAL = 10;

export const DEFAULT_LINE_SET = [
    {
        'points': [
            {
                'x': 0,
                'y': 0
            },
            {
                'x': 100,
                'y': 0
            },
            {
                'x': 100,
                'y': 100
            },
            {
                'x': 0,
                'y': 100
            }
        ],
        // readOnly: true
    }
];