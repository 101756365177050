import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NoImgSVG } from '../svg';

const Container = styled.div<{height?: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${({height}) => height};
  height: 100%;
  background-color: hsl(206, 26%, 95%);
  cursor: pointer;
  padding: 20%;
`;

const Text = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.ui};
    font-size: 16px;
    font-weight: 600;
    fill: hsla(208, 8%, 39%, 0.45);
    opacity: 0.5;
    margin-top: 10px;
`;

const NoImage: React.FC<NoImageProps>  = ({ isLoading, height }) => {

  const { t } = useTranslation('Common');

  return (
    <Container height={height}>
      {
        isLoading ?
          <Text>{t('loading')}</Text> :
          <>
            <NoImgSVG />
            <Text>{t('noImage')}</Text>
          </>
      }
    </Container>
  );
};

export default NoImage;