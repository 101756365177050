import { useState, useCallback, useRef } from 'react';
import { useNotification } from 'scorer-ui-kit';
import { getCameraImage } from '../apis';

export const useImage = () => {
  const [image, setImage] = useState('');
  const [imgLoading, setImgLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const { sendNotification } = useNotification();

  const notify = useRef<any>();
  notify.current = sendNotification;

  const fetchImage = useCallback((streamName: string, original, streamType) => {
    if (!streamName) return;
    getCameraImage(streamName, original, streamType).then((res) => {
      if (res.data.byteLength > 100) {
        setImage('data:image/jpg;base64,' + Buffer.from(res.data, 'binary').toString('base64'));
      }
      setImgLoading(false);
    }).catch((err) => {
      console.error(err);
      setImgLoading(false);
      setError(err.message);
      notify.current({ type: 'error', message: err.message, isPinned: true });
    });
  }, []);

  return { state: { image, imgLoading, error }, actions: { fetchImage } };
};