import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Icon } from 'scorer-ui-kit';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -40px;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const Divider = styled.div`
  width: auto;
  height: 1px;
  border-radius: 3px;
  background-color: #efefef;
  margin-left: 41px;
`;

const Title = styled.label`
  margin: 2px 0px 16px 0px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: bold;
  color: rgba(120, 138, 144, 0.72);
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 20px 15px 0;
  padding: 3px 2px 2px 3px;
`;

const HeadingContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface Custom {
  customComponent?: ReactElement
}

type Props = SubHeadingProps & Custom;

const SubHeading: React.FC<Props> = ({ icon, headingTitle, customComponent }) => {
  return (
    <Container>
      <HeaderContainer>
        <IconWrapper>
          <Icon weight='heavy' icon={icon} color='dimmed' size={17} />
        </IconWrapper>
        <HeadingContent>
          <Title>{headingTitle}</Title>
          <div>{customComponent}</div>
        </HeadingContent>
      </HeaderContainer>
      <Divider />
    </Container>
  );
};

export default SubHeading;