import React from 'react';
import styled, { css } from 'styled-components';
import { Icon as SelectIcon } from 'scorer-ui-kit';

const Button = styled.button<{ expanded: boolean, selectType: SelectType, isLoading: boolean, disabled: boolean, width: string, paddingRight?: string }>`
  height: 100%;
  display: flex;
  align-items: center;
  color: hsl(208, 8%, 39%);
  border: 1px solid rgb(217,218,217);
  box-sizing: border-box;
  width: ${props => props.width ? props.width : 'auto'};
  justify-content: space-between;
  padding-left: 3px;
  padding-right: ${props => props.paddingRight ? props.paddingRight : '10px'};
  white-space: nowrap;
  outline: none;
  color: #808080;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: '0px 3px 3px 0px';
  ${props => (props.expanded && css`
      background-color: hsl(204, 50%, 68%);
      color: hsl(0, 0%, 100%);
      svg{
        fill: hsl(0, 0%, 100%);
        stroke: hsl(0, 0%, 100%);
      }
  `)}
  ${props => (props.selectType === 'primary') && css`
    height: 40px;
    line-height: 0.94;
    letter-spacing: normal;
    color: hsl(207, 5%, 57%);
    background-color: hsl(0, 0%, 100%);
    border-radius: 3px;
    border: solid 1px hsl(120, 1%, 85%);
    svg{
      fill: hsla(205, 60%, 57%, 0.65);
      stroke: hsla(205, 60%, 57%, 0.65);
    }
  `}
  ${props => (props.selectType === 'primary' && props.disabled) && css`
      color: hsla(0, 0%, 46%, 0.5);
      font-style: italic;
  `}

  ${props => (props.isLoading === true) && css`
      border-top-right-radius: none;
      border-bottom-right-radius: none;
      border-right: none;
  `};
  ${({ disabled }) => (disabled && css`
      background-color: hsla(206,36.8%,96.3%,1.000);
  `)}

`;

const ButtonText = styled.span<{ expanded: boolean, isPlaceholder?: boolean }>`
  margin-left: 10px;
  margin-right: 17px;
  color: ${({ expanded, isPlaceholder }) => !expanded ? (isPlaceholder ?  'rgba(118, 118, 118, 0.5)' : '#8b9196') : '#ffffff'};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  line-height: 1.56;
  font-style: ${({ isPlaceholder }) => isPlaceholder ?  'italic' : 'inherit'};
`;

interface ISelectButtonProps extends React.InputHTMLAttributes<HTMLButtonElement> {
  title: string;
  expanded: boolean;
  icon?: string;
  IconProps?: any;
  hideArrow?: boolean;
  selectType?: SelectType;
  width?: string;
  isLoading?: boolean;
  disabled?: boolean;
  paddingRight?: string;
  isPlaceholder?: boolean;
}

const SelectButton: React.FC<ISelectButtonProps> = ({ title, expanded, onClick, hideArrow, selectType = 'toolbar', width = 'auto', isLoading = false, disabled = false, paddingRight, isPlaceholder }) => {
  const getArrow = (expanded = false, hideArrow = false) => {
    if (!hideArrow) {
      return (expanded ? <SelectIcon size={12} color='inverse' icon='Up' /> : <SelectIcon size={12} color='dimmed' icon='Down' />);
    } else {
      return null;
    }
  };

  return (
    <Button type='button' expanded={expanded} onClick={onClick} selectType={selectType} width={width} isLoading={isLoading} disabled={disabled} paddingRight={paddingRight}>      
      <ButtonText expanded={expanded} isPlaceholder={isPlaceholder}>{title}</ButtonText>
      {getArrow(expanded, hideArrow)}
    </Button>
  );
};

export default SelectButton;
