import styled, { css } from 'styled-components';

const SelectOption = styled.div<{ selected?: boolean, disabled?: boolean, selectType?: SelectType }>`
  height: 40px;
  font-size: 16px;
  color: hsl(0, 0%, 53%);
  min-width: 10rem;
  padding: 8px;
  cursor: pointer;
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  user-select: none;
  ${props => {
    if (props.disabled) {
      return 'font-style: normal;opacity: 0.5;hsla(215, 67%, 93%,.5)';
    } else if (props.selected) {
      return 'font-style: bold; color: hsl(205, 96%, 63%);';
    } else {
      return 'font-style: bold; color: hsl(0, 0%, 53%);';
    }
  }}
  :hover{
    opacity: .75;
  };
  ${props => (props.selectType === 'primary') && css`
      color: hsl(207, 5%, 57%);
      :hover {
        color: hsl(207, 41%, 53%);
        background-color: hsl(205, 100%, 94%);
      }
      div {
        border: none;
      }
  `}
  ${props => (props.selected && props.selectType === 'primary') && css` color: hsl(206, 22%, 29%);`}
`;
const OptionText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
`;

export { SelectOption, OptionText };