import { formatDistance } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import i18n from 'i18next';

export const getTimeSince = (date: string) => {
    if (!date)
        return '-';
    const lang = i18n.language === 'ja' ? ja : enUS;
    try {
        const result = formatDistance(
            new Date(date), new Date(new Date().toLocaleString('ja-jp', { timeZone: 'Asia/Tokyo' })),
            { locale: lang, addSuffix: true, includeSeconds: true }
        );
        if (lang === ja) {
            return result;
        } else {
            return result.charAt(0).toUpperCase() + result.slice(1);
        }
    } catch (err) {
        console.error(err);
        return '-';
    }
};