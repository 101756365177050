import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SubHeading from '../components/SubHeading';
import { useTranslation } from 'react-i18next';
import { getCheckedLogs } from '../apis';
import { Spinner } from 'scorer-ui-kit';

const Container = styled.div`
    margin-top: 43px;
`;

const RowContainer = styled.div<{ isFirst?: boolean }>`
    display: flex;
    ${({ isFirst }) => isFirst && 'margin-top: 4px;'}
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const RightColumn = styled.div`
    flex-wrap: wrap;
    display: flex;
`;

const LogTime = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 14px;
    color: rgba(120, 138, 144, 0.72);
    margin: 13px 0 0 40px;
`;

const DateText = styled.div`
    margin-top: 13px;
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 14px;
    color: #788b91;
    white-space: nowrap;
`;

const Day = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 14px;
    font-style: italic;  
    color: rgba(120, 138, 144, 0.72);
    margin-top: 13px;
    white-space: nowrap;
`;

const Divider = styled.div`
    height: 1px;
    background-color: #efefef;
    margin-top: 18px;
`;

const SinnerWrapper = styled.div`
    margin-top: 10px;
`;

const TableRow: React.FC<TableRowProps> = ({ index, logDetail }) => {
  const { t } = useTranslation(['CameraDetails']);

  let day;

  switch (index) {
  case 0:
    day = t('checkedLogTab.today');
    break;
  case 1:
    day = t('checkedLogTab.yesterday');
    break;
  case 2:
    day = t('checkedLogTab.weekdays.' + new Date(Object.keys(logDetail)[0]).getDay());
    break;
  default:
    day = '-';
    break;
  }

  return (
    <ColumnContainer>
      <RowContainer isFirst={index === 0}>
        <ColumnContainer>
          <DateText>
            {Object.keys(logDetail)[0]}
          </DateText>
          <Day>
            {day}
          </Day>
        </ColumnContainer>
        <RightColumn>
          {
            logDetail[Object.keys(logDetail)[0]].map((time: string) => <LogTime key={time}>{time}</LogTime>)
          }
          {
            logDetail[Object.keys(logDetail)[0]].length === 0 && <LogTime>-</LogTime>
          }

        </RightColumn>
      </RowContainer>
      <Divider />
    </ColumnContainer>
  );
};

const CheckedLogTab: React.FC<CheckedLogTabProps> = ({ cameraID }) => {
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(['CameraDetails', 'Cameras', 'Common']);

  const fetchLogs = useCallback(() => {
    getCheckedLogs(cameraID).then(({ data: { data, status_code } }) => {
      if (status_code === '0') {
        setLogData(data);
      }
      setLoading(false);
    }).catch((err) => {
      console.error(err.message);
      setLoading(false);
    });
  }, [cameraID]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  return (
    <Container>
      <SubHeading id='checked-logs' icon='Success' headingTitle={t('checkedLogTab.timesChecked')} />
      {
        loading ?
          <SinnerWrapper>
            <Spinner styling='primary' size='medium' />
          </SinnerWrapper> :
          logData.map((logDetail, index) => <TableRow index={index} key={Object.keys(logData)[index]} logDetail={logDetail} />)
      }
    </Container>
  );
};

export default CheckedLogTab;