import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Content, PageHeader, Spinner, usePoll } from 'scorer-ui-kit';
import CameraCard from './CameraCard';
import { useTranslation } from 'react-i18next';
import { useCameras } from '../hooks/useCameras';
import { CAMERAS_REFRESH_INTERVAL } from '../constants';

const CamerasContainer = styled.div`
  margin-top: 52px;
  display: flex;
  flex-wrap: wrap;
`;

const NoCamerasText = styled.div`
  margin-top: 17px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-style: italic;
  color: rgba(118, 118, 118, 0.5);
`;

const Cameras = () => {
  const { state: { cameras, loading }, actions: { fetchCameras } } = useCameras();
  const { t } = useTranslation(['Cameras', 'Common']);

  useEffect(() => {
    document.title = t('Common:cameras') + ' | Shelf Monitoring';
  }, [t]);

  usePoll(async() => {
    fetchCameras();
  }, CAMERAS_REFRESH_INTERVAL * 1000);

  return (
    <Content>
      <PageHeader title={t('Common:cameras')} icon='Camera' introductionText={t('introductionText')} />
      <CamerasContainer>
        {loading ?
          <Spinner size='large' styling='primary' /> :
          cameras && cameras.map((camera: Camera) => <CameraCard key={camera.stream_id} camera={camera} />)}
        {
          !loading && cameras.length === 0 &&
            <NoCamerasText>{t('noCamerasAvailable')}</NoCamerasText>
        }
      </CamerasContainer>
    </Content>
  );
};

export default Cameras;