import React from 'react';
import styled, { css } from 'styled-components';

const Progress = styled.div<{ percent: string, strokeColor: string }>`
  border-radius: 6px;
  background-color: ${({ strokeColor }) => strokeColor};
  transition: width 400ms ease-in-out;
  height: 100%;
  border-radius: 6px;
  ${({ percent }) => css`width: ${percent}%;`};
`;

const Container = styled.div`
  width: 100%;
  height: 11px;
  border-radius: 6px;
  background-color: hsl(0, 0%, 93%);
`;

const ProgressBar: React.FC<{ percent: string, strokeColor: string }> = ({ percent, strokeColor }) => {
  return (
    <Container>
      <Progress percent={percent} strokeColor={strokeColor} />
    </Container>);
};

export default ProgressBar;
