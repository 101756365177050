import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Common_en from './locales/en/Common.json';
import Cameras_en from './locales/en/Cameras.json';
import CameraDetails_en from './locales/en/CameraDetails.json';
import EditAreas_en from './locales/en/EditAreas.json';
import Settings_en from './locales/en/Settings.json';

import Common_ja from './locales/ja/Common.json';
import Cameras_ja from './locales/ja/Cameras.json';
import CameraDetails_ja from './locales/ja/CameraDetails.json';
import EditAreas_ja from './locales/ja/EditAreas.json';
import Settings_ja from './locales/ja/Settings.json';

const resources = {
  en: {
    Common: Common_en,
    Cameras: Cameras_en,
    CameraDetails: CameraDetails_en,
    EditAreas: EditAreas_en,
    Settings: Settings_en
  },
  ja: {
    Common: Common_ja,
    Cameras: Cameras_ja,
    CameraDetails: CameraDetails_ja,
    EditAreas: EditAreas_ja,
    Settings: Settings_ja
  },
};

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ja',
    lng: localStorage.getItem('language') || navigator.language,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultNS: 'Common',
    ns: ['Common', 'Cameras', 'CameraDetails', 'EditAreas', 'Settings'],
  });

export default i18n;