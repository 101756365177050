import React, { useCallback, useState } from 'react';
import { TextArea, Button, ButtonWithLoading } from 'scorer-ui-kit';
import styled from 'styled-components';
import SubHeading from '../components/SubHeading';
import { useTranslation } from 'react-i18next';
import { updateNotes } from '../apis';

const Container = styled.div`
    margin-top: 42px;
`;

const NotesTextArea = styled(TextArea)`
    margin-top: 17px;
    max-width: 100%;
    min-width: 100%;
    min-height: 60px;
    font-size: 14px;
    color: #8B9196;
    border-color: hsla(120,1.3%,85.3%,0.5);
    min-height: 270px;
`;

const CancelButton = styled(Button)`
    margin-right: 10px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    margin-top: -3px;
`;

const Notes = styled(NotesTextArea)<{isNote: boolean}>`
    font-style: ${({isNote}) => isNote ? 'normal' : 'italic'};
    border: none;
    margin-top: 13px;
    padding: 0px !important;
    color: rgba(118, 118, 118, 0.5);
`;

const NotesTab: React.FC<NotesTabProps> = ({ cameraID, notesEdit, onClickEditNotes, notes, fetchCameraDetails }) => {
  const [newNotes, setNewNotes] = useState(notes);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(['CameraDetails', 'Cameras', 'Common']);

  const CustomComponent: React.FC = () => {

    const onCancel = useCallback(() => {
      onClickEditNotes(false);
      setNewNotes(notes);
    }, []);

    const onSave = useCallback(() => {
      setLoading(true);
      updateNotes(cameraID, newNotes).then(({ data: { status_code } }) => {
        if (status_code === '0') {
          fetchCameraDetails();
        }
        setLoading(false);
        onClickEditNotes(false);
      }).catch((err) => {
        console.error(err);
        setLoading(false);
      });
    }, []);

    return (
      notesEdit ?
        <ButtonsContainer>
          <CancelButton size='small' design='secondary' onClick={onCancel}>{t('Common:form.cancel')}</CancelButton>
          <ButtonWithLoading loading={loading} onClick={onSave} size='small'>{t('Common:form.save')}</ButtonWithLoading>
        </ButtonsContainer> :
        null
    );
  };

  return (
    <Container>
      <SubHeading id='notes' icon='Edit' headingTitle={t('notes')} customComponent={<CustomComponent />} />
      {notesEdit ? 
        <NotesTextArea maxLength={256} value={newNotes} onChange={(e) => setNewNotes(e.target.value)} fieldState='default' readOnly={!notesEdit}>{t('notesTab.noNotes')}</NotesTextArea> :
        <Notes style={{ resize: 'none', backgroundColor: 'transparent'}} fieldState='disabled' isNote={newNotes ? true : false}>
          {newNotes ? 
            newNotes : t('notesTab.noNotes')}
        </Notes>}
    </Container>
  );
};

export default NotesTab;