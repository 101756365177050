import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Button, Icon } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ProgressBar from './ProgressBar';

const Container = styled.div`
    display: flex;
    height: auto;
    padding: 0 8px 0 1px;
    border-radius: 5px;
    border: solid 1px #eeeeee;
    margin-bottom: 8px;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 89%;
`;

const IDLabel = styled.label`
    width: 26px;
    height: 19px;
    margin-top: 7px;
    opacity: 0.55;
    font-family: ${({ theme }) => theme.fontFamily.ui};
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    text-align: center;
    color: #5a6269;
`;

const LeftContainer = styled(InnerContainer)`
    width: 26px;
`;

const LineIconWrapper = styled.div<{ toggle: number }>`
    width: 16px;
    height: 15px;
    margin: 25px 2.3px 12px 5px;
    opacity: 0.5;
    border-radius: 1.5px;
    background-image: linear-gradient(133deg, #71c3ed, #5baced);
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ toggle }) => toggle ? css`
      pointer-events: none;
      background-image: none;
      background: lightgray;
    `:
    css`
    &:hover {
      opacity: 1;
      cursor: pointer;
    }`}

`;

const AreaName = styled.label`
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 16px;
    font-weight: 500;
    color: #5a6269;
    margin-top: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 82%;
`;

const Divider = styled.div`
    width: 1px;
    height: 80px;
    margin-right: 9px;
    background-color: #eeeeee;
    position: inherit;
`;

const AnalysisContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1px;
`;

const AnalysedItem = styled.label`
    margin: 0 0 0 5px;
    opacity: 0.57;
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 12px;
    font-weight: 500;
    color: #5a6269;
`;

const ProgressBarWrapper = styled.div`
    display: flex;
    margin-top: 6px;
    align-items: center;
`;

const ProgressValue = styled.label`
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 12px;
    font-weight: 500;
    color: #5a6269;
    margin-left: 13px;
    margin-top: -3px;
`;

const EditButton = styled(Button)`
    height: 20px;
    font-size: 12px;
    padding: 0px 10.5px 0px 9.5px;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const AlertContainer = styled.div`
    display: flex;
`;

const AlertInfoContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-right: 15px;
    margin-top: 4px;
`;

const CircleIcon = styled.div<{ isWarning?: boolean }>`
    width: 6px;
    height: 6px;
    border-radius: 5px;
    margin-right: 9px;
    margin-left: 2px;
    background-color: ${({ isWarning }) => isWarning ? '#d7d16a' : '#de6b6b'};
`;

const AlertText = styled.div`
    opacity: 0.57;
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 12px;
    font-weight: 500;
    color: #5a6269;
    margin-right: 3px;
    white-space: nowrap;
`;

const AlertValue = styled(AlertText)`
    opacity: 1;
`;

const EmptyText = styled.div`
    margin-top: 18px;
    font-family: ${({ theme }) => theme.fontFamily.data};
    font-size: 14px;
    font-style: italic;
    text-align: center;
    color: rgba(118, 118, 118, 0.5);
`;

const NoAreaContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
`;

const NoAreaButton = styled(EditButton)`
    width: max-content;
    margin-top: 13px;
`;

const AlertInfo: React.FC<AlertInfoProps> = ({ isWarning = false, value }) => {
  const { t } = useTranslation(['EditAreas']);

  return (
    <AlertInfoContainer>
      <CircleIcon isWarning={isWarning} />
      <AlertText>{isWarning ? t('warningAt') : t('criticalAt')}</AlertText>
      <AlertValue>{value}%</AlertValue>
    </AlertInfoContainer>
  );
};

const CameraArea: React.FC<any> = ({ type, area: { index, id, name, filled_percent, stock_name, warning_level, critical_level }, edit = false, updateOverlay, toggle }) => {
  const { push } = useHistory();
  const { cameraID }: Params = useParams();
  const { t } = useTranslation(['EditAreas']);

  const onEdit = () => {
    if (type === 'edit') {
      push(`/cameras/${cameraID}/edit-areas`);
    } else {
      push(`/cameras/${cameraID}/edit-areas/${id}`);
    }
  };

  const onAdd = () => {
    push(`/cameras/${cameraID}/add-area`);
  };

  const onMouseHover = () => {
    updateOverlay(index);
  };

  const onMouseLeave = () => {
    updateOverlay(0);
  };

  const getColor = useCallback(() => {
    if (filled_percent < critical_level) {
      return '#f27f7f';
    }
    if (filled_percent < warning_level) {
      return '#d7d16a';
    }

    return '#87d58f';
  }, [filled_percent, critical_level, warning_level]);

  return (
    <Container>
      <LeftContainer>
        {!type &&
          <>
            <IDLabel>{index}</IDLabel>
            <LineIconWrapper onMouseEnter={onMouseHover} toggle={toggle} onMouseLeave={onMouseLeave}>
              <Icon icon='FeatureLineUi' size={10} color='inverse' />
            </LineIconWrapper>
          </>}
      </LeftContainer>
      <Divider />
      <InnerContainer>
        {
          !type ?
            <>
              <TitleContainer>
                <AreaName title={name}> {name} </AreaName>
                {edit && <EditButton onClick={onEdit} design='secondary'>{t('edit')}</EditButton>}
              </TitleContainer>
              <AnalysisContainer>
                <Icon icon='Analyse' size={10} color='dimmed' />
                <AnalysedItem>{stock_name}</AnalysedItem>
              </AnalysisContainer>
              {!edit ?
                <ProgressBarWrapper>
                  <ProgressBar percent={filled_percent} strokeColor={getColor()} />
                  <ProgressValue>{filled_percent}%</ProgressValue>
                </ProgressBarWrapper> :
                <AlertContainer>
                  <AlertInfo value={warning_level} isWarning />
                  <AlertInfo value={critical_level} />
                </AlertContainer>}
            </> :
            <>
              <EmptyText>{t(type === 'edit' ? 'noAreas' : 'createArea')}</EmptyText>
              <NoAreaContainer>
                <NoAreaButton onClick={type === 'edit' ? onEdit : onAdd} design='secondary'>{t(type === 'edit' ? 'editAreas' : 'addArea')}</NoAreaButton>
              </NoAreaContainer>
            </>

        }
      </InnerContainer>
    </Container>
  );
};

export default CameraArea;