import { useState, useCallback, useRef } from 'react';
import { useNotification } from 'scorer-ui-kit';
import { getAllCameras } from '../apis';

export const useCameras = () => {
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const { sendNotification } = useNotification();

  const notify = useRef<any>();
  notify.current = sendNotification;

  const fetchCameras = useCallback(async () => {
    getAllCameras().then(({ data: { data, status_code, message } }) => {
      if (status_code === '0') {
        setCameras(data);
      } else {
        console.error(message);
        setError(message);
        notify.current({ type: 'error', message: message, isPinned: true });
      }
      setLoading(false);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
      setError(err.message);
      notify.current({ type: 'error', message: err.message, isPinned: true });
    });
  }, []);

  return { state: { cameras, loading, error }, actions: { fetchCameras } };
};