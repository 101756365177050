import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import SelectButton from '../atoms/SelectButton';
import SelectList from '../atoms/SelectList';
import { SelectOption, OptionText } from '../atoms/SelectOption';
import { CheckMarkSVG } from '../../svg';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{ disabled?: boolean, width?: string, height?: string }>`
  position: relative;
  outline: none;
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '40px'};
  & > button {
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '40px'};
    border-radius: 3px;
    border: solid 1px #d9dad9;
  }
`;

const RadioBox = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #62768c;
  margin-right: 10px;
  flex-shrink: 0;
`;

const CheckMarkIcon = styled(CheckMarkSVG)`
  width: 14px;
  height: 14px;
  margin-right: 10px;
  flex-shrink: 0;
`;

interface ListItem {
  title: string;
  value: string;
}

interface ISelectProps {
  title?: string | number;
  list: (string | ListItem | number)[];
  selected: string;
  onSelect: (item: string | string[] | number | number[]) => void;
  showFilter?: boolean;
  clearable?: boolean;
  icon?: string;
  IconProps?: any;
  disabled?: boolean;
  width?: string;
  height?: string;
  paddingRight?: string;
  boxWidth?: string;
}

const Select: React.FC<ISelectProps> = ({ list, selected, icon, IconProps, clearable, onSelect, disabled, width, height, paddingRight, boxWidth = '100%' }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation(['EditAreas', 'Settings']);

  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const select = useCallback((item: string | number) => {
    if (clearable && selected === item) {
      onSelect('');
    } else {
      onSelect(item);
    }
    setExpanded(false);
  }, [onSelect, clearable, selected]);

  const onBlur = useCallback((e: any) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setExpanded(false);
      }
    }, 0);
  }, []);

  const getTitle = useCallback(() => {
    switch (selected) {
    case 'chooseStock':
    {
      const translated = t('chooseStock');
      return translated;
    }
    case 'chooseEmailService':
    {
      const translated = t('Settings:chooseEmailService');
      return translated;
    } 
    default:
      return selected;
    }
  }, [t, selected]);

  return (
    <Container tabIndex={0} onBlur={onBlur} disabled={disabled} width={width} height={height}>
      <SelectButton disabled={disabled} expanded={expanded} paddingRight={paddingRight} onClick={disabled ? () => { } : toggle} title={getTitle()} icon={icon} IconProps={IconProps} isPlaceholder={selected === 'chooseStock' || selected === 'chooseEmailService'} />
      <SelectList expanded={expanded} boxWidth={boxWidth}>
        {
          list.map((item: string | ListItem | number, index) => {

            if (index === 0) return null;
            const value = ((typeof item === 'string') || (typeof item === 'number')) ? item : item.value;
            const title = ((typeof item === 'string') || (typeof item === 'number')) ? item : item.title;
            return (
              <SelectOption selected={selected === value} onClick={() => { select(value); }} key={title}>
                {selected === value ? <CheckMarkIcon /> : <RadioBox />}<OptionText title=''>{title}</OptionText>
              </SelectOption>
            );
          })
        }
      </SelectList>
    </Container>
  );
};

export default Select;
