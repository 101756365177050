import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { ButtonWithIcon, Label, Switch, Spinner } from 'scorer-ui-kit';
import CameraInfo from '../components/CameraInfo';
import LineArea from './LineArea';
import CameraArea from '../components/CameraArea';
import { useTranslation } from 'react-i18next';
import { getTimeSince } from '../utils';
import { updateCamera } from '../apis';
import { ANALYSIS_IMAGE_DIR, ANALYSIS_IMAGE_REFRESH_INTERVAL } from '../constants';
import NoImage from '../components/NoImage';
import { ThemeProvider } from 'styled-components';
import { hiddenPointsTheme } from '../customTheme';

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 38px;
`;

const Divider = styled.div`
  width: 1px;
  height: 34px;
  margin-right: 24px;
  background-color: #eeeeee;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LineContainer = styled.div`
  width: 77.5%;
`;

const CheckedButton = styled(ButtonWithIcon)`
  margin-bottom: 25px;
  padding: 0 9px 0 17px;
`;

const AreasContainer = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  max-height: ${({ height }) => height}px;
  min-width: 252px;
  margin-left: 20px;
  width: 40.5%;
  overflow: auto;
`;

const LineAreaContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SwitchWrapper = styled.div`
  margin-top: 12px;
  display: flex;
`;

const SwitchLabel = styled(Label)`
  margin-left: 5px;
  margin-top: 3px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  right: 85%;
  top: 3px;
`;

const CheckedButtonWrapper = styled.div`
  position: relative;
`;

const AnalysisImage = styled.img`
  width: 100%;
  min-height: 295px;
  margin-bottom: -4px;
`;

const AnalysisTab: React.FC<any> = ({ cameraData: { stream_id, stream_name, notification_enabled, last_analysis_time, last_checked, show_analysis, areas = [] }, updateCameraData, image, imgLoading, error }) => {
  const [toggle, setToggle] = useState(show_analysis);
  const [linesData, setLinesData] = useState<PointSet[]>([]);
  const [checkedLoading, setCheckedLoading] = useState(false);
  const [imageHeight, setImageHeight] = useState(295);
  const [overlayIndex, setOverlayIndex] = useState(0);
  const [analysisImage, setAnalysisImage] = useState(ANALYSIS_IMAGE_DIR + stream_name + '_analysis.jpg?' + new Date());

  const { t } = useTranslation(['CameraDetails', 'Cameras', 'Common']);

  const onClickToggle = () => {
    setToggle(toggle ? 0 : 1);
  };

  const onClickChecked = () => {
    setCheckedLoading(true);
    updateCamera(stream_id.toString(), 'mark_checked').then(({ data: { status_code } }) => {
      if (status_code === '0') {
        updateCameraData();
      }
      setCheckedLoading(false);
    }).catch((err) => {
      setCheckedLoading(false);
      console.error(err);
    });
  };

  useEffect(() => {
    const newLinesSet: PointSet[] = areas.map(({ id, points, index }: PointSet) => {
      return { id, points, readOnly: true, index, areaName: index };
    });
    setLinesData(newLinesSet);
  }, [areas, overlayIndex]);

  const updateImageHeight = useCallback((newHeight) => {
    setImageHeight(newHeight);
  }, []);

  const updateOverlay = useCallback((index: number) => {
    setOverlayIndex(index);
  }, []);

  const onError = () => {
    setAnalysisImage('');
  };

  useEffect(() => {
    let interval: any;
    if (toggle) {
      interval = setInterval(() => {
        const img = new Image();
        const imgUrl = ANALYSIS_IMAGE_DIR + stream_name + '_analysis.jpg?' + new Date();
        img.src = imgUrl;
        img.onload = () => {
          setAnalysisImage(imgUrl);
        };
        img.onerror = () => {
          setAnalysisImage('');
        };
      }, ANALYSIS_IMAGE_REFRESH_INTERVAL * 1000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return (() => {
      clearInterval(interval);
    });
  }, [stream_name, toggle]);

  return (
    <>
      <InfoContainer>
        <LeftContainer>
          <CameraInfo icon='DateTime' infoTitle={t('analysisTab.lastUpdated')} value={getTimeSince(last_analysis_time)} />
          <Divider />
          <CameraInfo icon='Success' infoTitle={t('analysisTab.lastChecked')} value={getTimeSince(last_checked)} />
          <Divider />
          <CameraInfo icon={notification_enabled ? 'Notifications' : 'NotificationsOff'} infoTitle={t('analysisTab.notifications')} value={notification_enabled ? t('analysisTab.enabled') : t('analysisTab.disabled')} />
        </LeftContainer>
        <CheckedButtonWrapper>
          {checkedLoading &&
            <SpinnerWrapper>
              <Spinner size='medium' styling='primary' />
            </SpinnerWrapper>}
          <CheckedButton onClick={onClickChecked} position='left' size='small' icon='Success'>{t('analysisTab.markAsChecked')}</CheckedButton>
        </CheckedButtonWrapper>
      </InfoContainer>

      <LineAreaContainer>
        <LineContainer>
          {toggle ?
            <>
              {analysisImage !== '' ?
                <AnalysisImage alt='analysis' src={analysisImage} onError={onError} /> :
                <NoImage />}
            </> :
            <ThemeProvider theme={hiddenPointsTheme}>
              <LineArea image={image} imgLoading={imgLoading} error={error} linesData={linesData} updateImageHeight={updateImageHeight} overlayIndex={overlayIndex} readOnly />
            </ThemeProvider>}
          <SwitchWrapper>
            <Switch state={areas.length > 0 ? 'default' : 'disabled'} checked={toggle === 1 ? true : false} onChangeCallback={onClickToggle} />
            <SwitchLabel htmlFor='' labelText={t('analysisTab.showAnalysis')} />
          </SwitchWrapper>
        </LineContainer>

        <AreasContainer height={imageHeight}>
          {
            areas.length > 0 ?
              areas.map((area: Area) => <CameraArea key={area.id} area={area} updateOverlay={updateOverlay} toggle={toggle} />) :
              <CameraArea type='edit' area={{}} />
          }
        </AreasContainer>
      </LineAreaContainer>
    </>
  );
};

export default AnalysisTab;