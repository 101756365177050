import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Content, PageHeader, Switch, Icon, Tabs, Tab, TabList, TabContent, Button, Spinner, useNotification, usePoll } from 'scorer-ui-kit';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AnalysisTab from './AnalysisTab';
import NotesTab from './NotesTab';
import CheckedLogTab from './CheckedLogTab';
import { getCameraDetails, updateCamera } from '../apis';
import { useImage } from '../hooks/useImage';
import { StreamContext } from '../App';
import { CAMERA_DETAILS_REFRESH_INTERVAL, CAMERA_IMAGE_REFRESH_INTERVAL } from '../constants';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NotificationContainer = styled.div`
  display: flex;
  width: 64px;
  justify-content: space-between;
  height: max-content;
  margin-top: 7px;
`;

const Container = styled.div`
  margin-top: 24px;
`;

const TabDivider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: #efefef;
  margin-left: 0px;
`;

const TabButton = styled(Button)`
  height: 20px;
  margin-left: auto;
  margin-top: 6px;
  font-size: 12px;
  padding: 0px 10.5px 0px 9.5px;
`;

const TabText = styled.div`
  height: 34px;
`;

const CameraDetails = () => {
  const [cameraData, setCameraData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [toggleLoading, setToggleLoading] = useState<any>('default');
  const [activeTab, setActiveTab] = useState('analysis');
  const [notesEdit, setNotesEdit] = useState(false);
  const { state: { image, imgLoading, error }, actions: { fetchImage } } = useImage();

  const { push } = useHistory();
  const { cameraID }: Params = useParams();
  const { stream, setStream } = useContext(StreamContext);

  const { t } = useTranslation(['CameraDetails']);
  const { sendNotification } = useNotification();
  const notify = useRef<any>();
  notify.current = sendNotification;

  const getTitle = useCallback(() => {
    if (stream.desc) {
      return stream.desc + ' | Shelf Monitoring';
    }
    if (Object.keys(stream)[0]) {
      return Object.keys(stream)[0] + ' | Shelf Monitoring';
    }
    return 'Shelf Monitoring';
  }, [stream]);

  useEffect(() => {
    document.title = getTitle();
  }, [getTitle]);

  const onClickTab = useCallback((tab) => () => {
    setActiveTab(tab);
  }, []);

  const onClickEditAreas = () => {
    push(`/cameras/${cameraID}/edit-areas`);
  };

  const onClickEditNotes = (value: boolean) => {
    setNotesEdit(value);
  };

  const fetchCameraDetails = useCallback(() => {
    getCameraDetails(cameraID).then(({ data: { data, status_code, message } }) => {
      if (status_code === '0') {
        setCameraData(data);
        if (data.stream_name !== Object.keys(stream)[0])
          setStream({ [data.stream_name]: data.stream_type, desc: data.description });
      } else {
        notify.current({ type: 'error', message: message, isPinned: true });
      }
      setLoading(false);
    }).catch((err) => {
      console.error(err.message);
      setLoading(false);
      notify.current({ type: 'error', message: err.message, isPinned: true });
    });
  }, [cameraID, setStream, stream]);

  usePoll(async () => {
    fetchCameraDetails();
  }, CAMERA_DETAILS_REFRESH_INTERVAL * 1000);

  const onClickToggle = useCallback(() => {
    setToggleLoading('loading');
    updateCamera(cameraID, cameraData.notification_enabled ? 'disable_notification' : 'enable_notification').then(({ data: { status_code } }) => {
      if (status_code === '0') {
        setToggleLoading('default');
        setCameraData({ ...cameraData, notification_enabled: cameraData.notification_enabled ? 0 : 1 });
      } else {
        setToggleLoading('failure');
      }
    }).catch((err) => {
      setToggleLoading('failure');
      console.error(err);
    });
  }, [cameraData, cameraID]);

  useEffect(()=>{
    if (Object.keys(stream)[0]) {
      fetchImage(Object.keys(stream)[0], true, stream[Object.keys(stream)[0]]);
    }
  },[stream, fetchImage]);

  usePoll(async () => {
    if (Object.keys(stream)[0]) {
      fetchImage(Object.keys(stream)[0], true, stream[Object.keys(stream)[0]]);
    }
  }, CAMERA_IMAGE_REFRESH_INTERVAL * 1000);

  return (
    <Content>
      <HeaderContainer>
        <PageHeader title={stream.desc ? stream.desc : Object.keys(stream)[0]} icon='Camera' areaHref='/cameras' areaTitle={t('Common:cameras')} introductionText={t('Cameras:introductionText')} />
        {cameraData &&
          <NotificationContainer>
            <Icon icon={cameraData.notification_enabled ? 'Notifications' : 'NotificationsOff'} size={18} color='dimmed' />
            <Switch state={toggleLoading} checked={cameraData.notification_enabled ? true : false} onChangeCallback={onClickToggle} />
          </NotificationContainer>}
      </HeaderContainer>

      <Container>
        {
          loading ? <Spinner styling='primary' size='large' /> :
            cameraData &&
              <Tabs>
                <TabList defaultTabId='analysis'>
                  <Tab tabFor='analysis'><TabText onClick={onClickTab('analysis')}>{t('analysis')}</TabText></Tab>
                  <Tab tabFor='checked-logs'><TabText onClick={onClickTab('checked-logs')}>{t('checkedLog')}</TabText></Tab>
                  <Tab tabFor='notes'><TabText onClick={onClickTab('notes')}>{t('notes')}</TabText></Tab>
                  {activeTab === 'analysis' && <TabButton design='secondary' size='small' onClick={onClickEditAreas}>{t('analysisTab.editAreas')}</TabButton>}
                  {activeTab === 'notes' && <TabButton onClick={() => onClickEditNotes(true)} design='secondary' size='small'>{t('notesTab.editNotes')}</TabButton>}
                </TabList>
                <TabDivider />
                <TabContent tabId='analysis'>
                  <AnalysisTab cameraData={cameraData} updateCameraData={fetchCameraDetails} image={image} imgLoading={imgLoading} error={error} />
                </TabContent>
                <TabContent tabId='checked-logs'>
                  <CheckedLogTab cameraID={cameraID} />
                </TabContent>
                <TabContent tabId='notes'>
                  <NotesTab notes={cameraData.notes} notesEdit={notesEdit} onClickEditNotes={onClickEditNotes} fetchCameraDetails={fetchCameraDetails} cameraID={cameraID} />
                </TabContent>
              </Tabs>
        }
      </Container>
    </Content>
  );
};

export default CameraDetails;