import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { Button, ButtonWithIcon, Content, PageHeader, Spinner, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubHeading from '../components/SubHeading';
import LineArea from './LineArea';
import CameraArea from '../components/CameraArea';
import { getCameraDetails } from '../apis';
import { ThemeProvider } from 'styled-components';
import { hiddenPointsTheme } from '../customTheme';
import { useImage } from '../hooks/useImage';
import { StreamContext } from '../App';

const Container = styled.div`
    margin-top: 43px;
`;

const AreasContainer = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  max-height: ${({ height }) => height}px;
  min-width: 252px;
  margin-left: 20px;
  width: 40.5%;
  overflow: auto;
`;

const LineAreaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 30px;
`;

const LineWrapper = styled.div`
    width: 77.5%;
`;

const AddAreaButton = styled(ButtonWithIcon)`
    margin-right: 10px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    margin-top: -3px;
`;

const SinnerWrapper = styled.div`
    margin-top: 10px;
`;

const EditAreas = () => {
  const [cameraData, setCameraData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [linesData, setLinesData] = useState<PointSet[]>([]);
  const [imageHeight, setImageHeight] = useState(295);
  const [overlayIndex, setOverlayIndex] = useState(0);
  const { state: { image, imgLoading, error }, actions: { fetchImage } } = useImage();

  const { push } = useHistory();
  const { cameraID }: Params = useParams();
  const { stream, setStream } = useContext(StreamContext);
  const { t } = useTranslation(['EditAreas']);
  const { sendNotification } = useNotification();
  const notify = useRef<any>();
  notify.current = sendNotification;

  const getTitle = useCallback(() => {
    if (stream.desc) {
      return stream.desc + ' | Shelf Monitoring';
    }
    if (Object.keys(stream)[0]) {
      return Object.keys(stream)[0] + ' | Shelf Monitoring';
    }
    return 'Shelf Monitoring';
  }, [stream]);

  useEffect(() => {
    document.title = getTitle();
  }, [getTitle]);
  
  const fetchCameraDetails = useCallback(() => {
    getCameraDetails(cameraID).then(({ data: { data, status_code, message } }) => {
      if (status_code === '0') {
        setCameraData(data);
        if (data.stream_name !== Object.keys(stream)[0])
          setStream({ [data.stream_name]: data.stream_type, desc: data.description });
        const newLinesSet: PointSet[] = data.areas.map(({ id, points, index }: PointSet) => {
          return { id, points, readOnly: true, areaName: index, index };
        });
        setLinesData(newLinesSet);
      } else {
        notify.current({ type: 'error', message: message, isPinned: true });
      }
      setLoading(false);
    }).catch((err) => {
      console.error(err.message);
      setLoading(false);
      notify.current({ type: 'error', message: err.message, isPinned: true });
    });
  }, [cameraID, setStream, stream]);

  useEffect(() => {
    fetchCameraDetails();
  }, [fetchCameraDetails]);

  const updateImageHeight = useCallback((newHeight) => {
    setImageHeight(newHeight);
  }, []);

  const updateOverlay = useCallback((index: number) => {
    setOverlayIndex(index);
  }, []);

  useEffect(() => {
    if (Object.keys(stream)[0]) {
      fetchImage(Object.keys(stream)[0], true, stream[Object.keys(stream)[0]]);
    }
  }, [fetchImage, stream]);

  const CustomComponent: React.FC = () => {

    const onClickAddArea = useCallback(() => {
      push(`/cameras/${cameraID}/add-area`);
    }, []);

    const onClickFinish = useCallback(() => {
      push(`/cameras/${cameraID}`);
    }, []);

    return (
      <ButtonsContainer>
        <AddAreaButton position='left' size='small' icon='Add' design='secondary' onClick={onClickAddArea}>{t('addArea')}</AddAreaButton>
        <Button onClick={onClickFinish} size='small'>{t('finish')}</Button>
      </ButtonsContainer>
    );
  };

  return (
    <Content>
      <PageHeader title={stream.desc ? stream.desc : Object.keys(stream)[0]} icon='Camera' areaHref={`/cameras/${cameraID}`} areaTitle={t('cameraAndAreas')} introductionText={t('Cameras:introductionText')} />
      <Container>
        <SubHeading icon='FeatureLineUi' headingTitle={t('editAreas')} customComponent={<CustomComponent />} />
        {
          loading ?
            <SinnerWrapper>
              <Spinner styling='primary' size='large' />
            </SinnerWrapper> :

            cameraData &&
              <LineAreaContainer>
                <LineWrapper>
                  <ThemeProvider theme={hiddenPointsTheme}>
                    <LineArea image={image} imgLoading={imgLoading} error={error} linesData={linesData} updateImageHeight={updateImageHeight} overlayIndex={overlayIndex} readOnly />
                  </ThemeProvider>
                </LineWrapper>
                <AreasContainer height={imageHeight}>
                  {cameraData.areas.length > 0 ?
                    cameraData.areas.map((area: Area, index: number) => <CameraArea index={index} key={area.id} area={area} updateOverlay={updateOverlay} edit />) :

                    <CameraArea type='add' area={{}} />}
                </AreasContainer>
              </LineAreaContainer>
        }
      </Container>
    </Content>
  );
};

export default EditAreas;